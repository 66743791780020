<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">投放渠道</span>
                    <span class="condition_content">
                        <el-cascader
                            v-model="providerNos"
                            :options="providerCascader"
                            placeholder="投放渠道"
                            collapse-tags
                            filterable
                            clearable
                            :props="{ expandTrigger: 'hover', multiple: true }"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">添加时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="addTimes"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">表单时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="commitTimes"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">联系方式</span>
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入联系方式"
                            clearable
                            v-model.trim="contact"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">表单详情</span>
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入内容"
                            clearable
                            v-model.trim="formDetailSearch"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>
                <ExportFile
                    class="float_left_btn"
                    host="hunter"
                    path="/xbTrackFormRecord/export"
                    @before-export="onBeforeExport"
                    v-if="P(1010401)"
                />
                <el-button class="float_left_btn" v-if="P(1010402)" @click="isShowAddFormData = true">
                    插入表单
                </el-button>
                <el-dropdown @command="onCommand" class="float_left_btn" v-if="P(1010403)">
                    <el-button class="float_left_btn">导入表单</el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="douyin">抖音表单</el-dropdown-item>
                        <el-dropdown-item command="default">普通表单</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column header-align="center" prop="providerNo" label="投放渠道">
                <template slot-scope="scope">
                    <span>{{ providerMap ? providerMap[scope.row.providerNo] : '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="telNo" label="联系方式" min-width="120">
                <template slot-scope="scope">
                    <span>{{ contactType[scope.row.contactType] || '联系方式' }}: {{ scope.row.contact }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="province" label="地域">
                <template slot-scope="scope">
                    <span>{{ scope.row.province }}-{{ scope.row.city }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="表单详情" min-width="200">
                <template slot-scope="scope">
                    <div class="form_detail" v-if="scope.row.formDetailListMap">
                        <div v-for="(item, index) in scope.row.formDetailListMap" :key="index">
                            {{ item.name }}: <span>{{ item.value }}</span>
                        </div>
                    </div>
                    <div v-else-if="scope.row.consultContent">
                        {{ scope.row.consultContent }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="快递信息" min-width="200">
                <template slot-scope="scope">
                    <div class="express_info" v-if="scope.row.expressInfo">
                        <div>
                            姓名: <span>{{ scope.row.expressInfo.receiveName }}</span>
                        </div>
                        <div>
                            手机号: <span>{{ scope.row.expressInfo.receiveMobile }}</span>
                        </div>
                        <div>
                            收货地址: <span>{{ scope.row.expressInfo.receiveAddress }}</span>
                        </div>
                        <div>
                            快递公司: <span>{{ scope.row.expressInfo.companyName }}</span>
                        </div>
                        <div>
                            快递单号: <span>{{ scope.row.expressInfo.expressNo }}</span>
                        </div>
                    </div>
                    <span v-else>-</span>
                </template>
            </el-table-column>

            <el-table-column header-align="center" prop="commitTime" width="90" label="表单时间" />
            <el-table-column header-align="center" prop="addTime" width="90" label="添加时间" />
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />
        <AddFormData
            :projectNo="projectNo"
            :providerList="providerList"
            @onChange="xbTrackFormRecordListApi"
            :isShow.sync="isShowAddFormData"
        />
        <EditBatchUpload
            :projectNo="projectNo"
            :providerList="providerList"
            :isShow.sync="isShowEditBatchUpload"
            @onClose="onCloseEditBatchUpload"
        />
        <EditBatchUploadDy
            :providerList="providerList"
            :isShow.sync="isShowEditBatchUploadDy"
            @onClose="onCloseEditBatchUploadDy"
        />
        <UploadFormData :projectNo="projectNo" :isShow.sync="isShowUploadFormData" @onClose="onCloseUploadFormData" />
    </div>
</template>

<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import AddFormData from './components/form/AddFormData.vue'
import UploadFormData from './components/form/UploadFormData.vue'
import EditBatchUpload from './components/form/EditBatchUpload.vue'
import EditBatchUploadDy from './components/form/EditBatchUploadDy.vue'
import { contactType } from '@/js/config/opportunit.js'
import { getProviderCategoryData, getFormExpressList } from '@/js/api/advertApi'
import { xbTrackFormRecordList } from '@/js/api/advertApi.js'
import { filterQueryEmpty, isEmptyArray, listObjectField, listFormatMap } from '@/js/utils.js'

export default {
    data() {
        return {
            contactType,
            providerMap: null,
            providerList: [],
            projectNo: '',
            providerCascader: [],
            providerNos: [],
            addTimes: [],
            commitTimes: [],
            contact: '',
            formDetailSearch: '',

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            isShowAddFormData: false,
            isShowUploadFormData: false,
            isShowEditBatchUpload: false,
            isShowEditBatchUploadDy: false,
        }
    },
    components: { AddFormData, UploadFormData, EditBatchUpload, EditBatchUploadDy, ProjectSelect },

    created() {
        this.getProviderCategoryDataApi()
    },
    methods: {
        onReset() {
            this.providerNos = []
            this.addTimes = []
            this.commitTimes = []
            this.contact = ''
            this.formDetailSearch = ''

            this.tableData = []
            this.onPaginationChange('page', 1)
        },
        onProjectNoChange(val) {
            this.projectNo = val
            this.onReset()
        },

        onBeforeExport(cb) {
            cb(this.getParams())
        },
        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.xbTrackFormRecordListApi)
        },

        onCloseUploadFormData(state) {
            this.isShowUploadFormData = false
            if (state) this.onPaginationChange('page', 1)
        },

        onCommand(type) {
            if (type === 'douyin') {
                this.onShowEditBatchUploadDy()
            } else {
                this.onShowEditBatchUpload()
            }
        },
        // 批量导入
        onShowEditBatchUpload() {
            this.isShowEditBatchUpload = true
        },
        onCloseEditBatchUpload(state) {
            this.isShowEditBatchUpload = false
            if (state) this.onPaginationChange('page', 1)
        },
        // 抖音表单(批量导入)
        onShowEditBatchUploadDy() {
            this.isShowEditBatchUploadDy = true
        },
        onCloseEditBatchUploadDy(state) {
            this.isShowEditBatchUploadDy = false
            if (state) this.onPaginationChange('page', 1)
        },

        getParams() {
            let params = { projectNo: this.projectNo, contact: this.contact, formDetailSearch: this.formDetailSearch }
            params.providerNos = !isEmptyArray(this.providerNos) ? this.providerNos.map((item) => item[1]) : null
            params.commitTimes = !isEmptyArray(this.commitTimes) ? this.commitTimes : null
            params.addTimes = !isEmptyArray(this.addTimes) ? this.addTimes : null

            params = filterQueryEmpty(params)
            return params
        },

        async xbTrackFormRecordListApi() {
            let params = {
                orderBy: '-id',
                page: this.page,
                size: this.pageSize,
                showPage: true,
                ...this.getParams(),
            }

            params = filterQueryEmpty(params)
            const res1 = await xbTrackFormRecordList(params)
            if (res1.data.list.length !== 0) {
                const _orderNos = listObjectField({ list: res1.data.list, key: 'orderNo' })
                const res2 = await getFormExpressList({ orderNos: _orderNos })

                const _map = listFormatMap({ list: res2.data, key: 'orderNo' })

                res1.data.list.forEach((item) => {
                    item.expressInfo = _map[item.orderNo]
                    if (item.formDetail) {
                        item.formDetailListMap = JSON.parse(item.formDetail)
                    }
                })
            }
            this.tableData = res1.data.list
            this.total = res1.data.total
            this.page = res1.data.pageNum
        },
        getProviderCategoryDataApi() {
            getProviderCategoryData().then((res) => {
                this.providerList = res.providerList
                this.providerMap = res.providerMap
                this.providerCascader = res.providerCascader
            })
        },
    },
}
</script>

<style lang="less" scoped>
.template {
    margin: 8px 0 0 0;
    font-size: 13px;
}
.form_detail,
.express_info {
    span {
        font-weight: 600;
        color: #000000;
    }
}
</style>
