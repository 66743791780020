<template>
    <el-dialog title="添加表单" :visible.sync="isShow" width="480px" :before-close="onClose" @open="onOpend">
        <el-form v-if="formData" :model="formData" :rules="rules" ref="formData" label-width="100px">
            <el-form-item label="项目" prop="projectNo">
                <ProjectSelect :defaultValue="formData.projectNo" @change="(val) => (formData.projectNo = val)" />
            </el-form-item>
            <el-form-item label="添加方式">
                <el-radio-group v-model="formType" @change="onFormTypeChange">
                    <el-radio-button label="default">表单</el-radio-button>
                    <el-radio-button label="dyProduct">商品</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="投放渠道" prop="providerNo">
                <el-select v-model="formData.providerNo" filterable placeholder="请选择">
                    <el-option
                        v-for="item in providerList"
                        :key="item.providerNo"
                        :label="item.name"
                        :value="item.providerNo"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="表单时间" prop="commitTime">
                <el-date-picker
                    v-model="formData.commitTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择日期"
                    :clearable="false"
                />
            </el-form-item>
            <el-form-item label="联系类型" prop="contactType">
                <el-select v-model="formData.contactType" placeholder="请选择">
                    <el-option v-for="item in contactTypeList" :key="item.val" :label="item.name" :value="item.val" />
                </el-select>
            </el-form-item>
            <el-form-item label="联系方式" prop="contact">
                <el-input placeholder="请输入" v-model.trim="formData.contact" />
            </el-form-item>
            <template v-if="formType === 'default'">
                <el-form-item label="所在地" prop="userAddress">
                    <el-cascader placeholder="请选择" v-model="formData.userAddress" :options="address" filterable />
                </el-form-item>
                <!-- <el-form-item label="搜索词" prop="searchWord">
                    <el-input placeholder="请输入" v-model="formData.searchWord" :maxlength="64" show-word-limit />
                </el-form-item> -->
                <el-form-item label="学员咨询问题" prop="consultContent">
                    <el-input
                        type="textarea"
                        :rows="3"
                        :maxlength="128"
                        show-word-limit
                        placeholder="请输入"
                        v-model="formData.consultContent"
                    />
                </el-form-item>
            </template>
            <template v-if="formType === 'dyProduct'">
                <el-form-item label="订单号" prop="orderNo">
                    <el-input placeholder="请输入" v-model="formData.orderNo" :maxlength="64" show-word-limit />
                </el-form-item>
                <el-form-item label="收货人姓名" prop="name">
                    <el-input placeholder="请输入" v-model="formData.name" :maxlength="64" show-word-limit />
                </el-form-item>
                <el-form-item label="收货地址" prop="address">
                    <el-input placeholder="请输入" v-model="formData.address" :maxlength="180" show-word-limit />
                </el-form-item>
                <el-form-item label="商品名称" prop="pProductName">
                    <el-input placeholder="请输入" v-model="formData.pProductName" :maxlength="64" show-word-limit />
                </el-form-item>
            </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmitForm">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import { address } from '@/js/config/address.js'
import { contactTypeList } from '@/js/config/opportunit.js'
import { xbTrackFormRecordCommit } from '@/js/api/advertApi.js'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'providerList', 'projectNo'],
    components: { ProjectSelect },
    data() {
        const _validatorContact = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入'))
            } else if (/^1[3456789]\d{9}$/.test(value)) {
                callback()
            } else if (/^1\d{10}-\d{1,4}$/.test(value)) {
                callback()
            } else if (/^1\d{10}转\d{1,4}$/.test(value)) {
                callback()
            } else if (/^1\d{10}#\d{1,4}$/.test(value)) {
                callback()
            } else {
                callback(new Error('请输入正确的手机号、分机号'))
            }
        }
        return {
            formType: 'default',

            address,
            contactTypeList,
            formData: null,
            rules: {
                providerNo: [{ required: true, message: '请选择', trigger: 'change' }],
                commitTime: [{ required: true, message: '请选择', trigger: 'change' }],
                contactType: [{ required: true, message: '请选择', trigger: 'change' }],
                contact: [
                    {
                        required: true,
                        validator: _validatorContact,
                        message: '请输入正确的手机号、分机号',
                        trigger: 'blur',
                    },
                ],
                orderNo: [{ required: true, message: '请输入', trigger: 'change' }],
            },
        }
    },
    computed: { ...mapGetters(['userInfo']) },
    methods: {
        onOpend() {
            this.formData = {
                projectNo: this.projectNo,
                providerNo: '',
                contactType: 'tel',
                contact: '',
                consultContent: '',
                commitTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                searchWord: '',
                userAddress: '',
                name: '',
                orderNo: '',
                address: '',

                pProductName: '',
            }
        },
        onClose() {
            this.formData = null
            this.$emit('update:isShow', false)
        },

        onFormTypeChange() {
            this.formData.userAddress = ''
            this.formData.consultContent = ''
            this.formData.name = ''
            this.formData.orderNo = ''
            this.formData.address = ''
            this.formData.pProductName = ''
        },
        onSubmitForm() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    const params = { ...this.formData, optId: this.userInfo.id }
                    if (this.formData.userAddress) {
                        params.province = this.formData.userAddress[0]
                        params.city = this.formData.userAddress[1]
                    }
                    if (this.formData.pProductName) {
                        params.formDetail = JSON.stringify([{ name: '商品名称', value: this.formData.pProductName }])
                    }
                    xbTrackFormRecordCommit(params).then(() => {
                        this.$emit('onChange')
                        this.$message.success('添加成功')
                        this.onClose()
                    })
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-date-editor,
.el-cascader,
.el-select {
    width: 100%;
}
</style>
