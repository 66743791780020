<template>
    <el-drawer title="批量导入" :visible.sync="isShow" @opened="onOpened" :before-close="onClose" size="1100px">
        <div class="opts">
            <el-upload :before-upload="onUpload" action="http">
                <el-button size="small" type="primary" :disabled="!activeProjectNo">
                    {{ activeProjectNo ? '批量导入' : '请选择项目' }}
                </el-button>
                <div slot="tip" class="el-upload__tip" v-if="fileName">{{ fileName }}</div>
            </el-upload>
            <el-link class="template" type="primary" :href="formExcelTemplate">
                下载导入表单模板
                <el-tooltip effect="dark" content="红色必填，黄色 直播带货必填 ，绿色选填" placement="top">
                    <i class="el-icon-warning-outline" />
                </el-tooltip>
            </el-link>
            <el-select v-model="activeProjectNo" placeholder="请选择项目" :disabled="tableData.length !== 0">
                <el-option
                    v-for="item in projectList"
                    :key="item.projectNo"
                    :label="item.projectName"
                    :value="item.projectNo"
                />
            </el-select>
            <el-alert class="alert" v-if="alertContainer.state" :title="alertContainer.content" type="warning" />
        </div>

        <el-table ref="tableContent" :data="tableData" border stripe @selection-change="onSelectionChange" :key="uuid">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-descriptions class="descriptions" :column="3" border>
                        <el-descriptions-item label="表单时间">{{ props.row['表单时间'] }}</el-descriptions-item>
                        <el-descriptions-item label="联系类型">{{ props.row['联系类型'] }}</el-descriptions-item>
                        <el-descriptions-item label="联系方式">{{ props.row['联系方式'] }}</el-descriptions-item>
                        <el-descriptions-item label="订单号">{{ props.row['form.订单号'] }}</el-descriptions-item>
                        <el-descriptions-item label="收货姓名">{{ props.row['form.收货姓名'] }}</el-descriptions-item>
                        <el-descriptions-item label="收货地址">{{ props.row['form.收货地址'] }}</el-descriptions-item>
                        <el-descriptions-item label="商品">{{ props.row['form.商品'] }}</el-descriptions-item>
                        <el-descriptions-item label="金额">{{ props.row['form.金额'] }}</el-descriptions-item>
                        <el-descriptions-item label="带货达人">{{ props.row['form.带货达人'] }}</el-descriptions-item>
                        <el-descriptions-item label="咨询内容">{{ props.row['咨询内容'] }}</el-descriptions-item>
                        <el-descriptions-item label="IP">{{ props.row['IP'] }}</el-descriptions-item>
                        <el-descriptions-item label="省">{{ props.row['省'] }}</el-descriptions-item>
                        <el-descriptions-item label="市">{{ props.row['市'] }}</el-descriptions-item>
                    </el-descriptions>
                </template>
            </el-table-column>
            <el-table-column align="center" type="selection" width="54" :selectable="onItemSelectState" />
            <el-table-column header-align="center" prop="渠道名称" label="渠道名称" />
            <el-table-column align="center" prop="表单时间" label="表单时间" />
            <el-table-column align="center" prop="联系方式" label="联系方式" />
            <el-table-column align="center" prop="归属" label="归属" />
            <el-table-column align="center" label="状态">
                <template slot-scope="scope">
                    <el-tag v-if="!scope.row.error" type="primary" effect="dark">正常</el-tag>
                    <el-tag v-else type="danger" effect="dark">{{ scope.row.error }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="提交状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.uploadState === 1" type="success">成功</el-tag>
                    <el-tag v-else-if="scope.row.uploadState === -1" type="danger">异常</el-tag>
                    <el-tag v-else type="info">等待上报</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <br />
        <div class="upload_container">
            <el-button :disabled="uploadProgress.state" @click="onSubmit" type="primary" plain>
                {{ uploadProgress.state ? '上传中' : '批量提交' }}
            </el-button>
            <el-button :disabled="uploadProgress.state" @click="onReset">重置上传</el-button>
            <div class="progress" v-if="uploadProgress.progress !== 0">
                <el-link type="success">成功: {{ uploadProgress.success }}</el-link>
                <el-link type="danger">失败: {{ uploadProgress.error }}</el-link>
                <el-progress :percentage="uploadProgress.progress" />
            </div>
        </div>
    </el-drawer>
</template>
<script>
import { getStaffList } from '@/js/api/staffApi'
import { contactTypeList } from '@/js/config/opportunit.js'
import { batchUploadxbTrackForm } from '@/js/api/advertApi.js'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'
import _config from '@/js/config'
import dayjs from 'dayjs'

export default {
    props: ['isShow', 'providerList'],
    computed: { ...mapGetters(['userInfo', 'projectList']) },
    data() {
        return {
            formExcelTemplate: _config.formExcelTemplate,
            fileName: '',
            tableData: [],
            uuid: '',
            activeProjectNo: '',
            uploadProgress: { state: false, progress: 0, success: 0, error: 0 },

            alertContainer: { state: false, content: '' },
        }
    },
    methods: {
        onOpened() {
            this.uploadProgress = { state: false, progress: 0, success: 0, error: 0 }
            this.checkedItem = []
            this.staffMap = {}
            this.getStaffListApi()
        },
        onClose() {
            this.fileName = ''
            this.checkedItem = []
            this.tableData = []
            this.activeProjectNo = ''
            this.$emit('update:isShow', false)
        },
        onUpload(file) {
            this.alertContainer = { state: false, content: '' }
            this.fileName = file.name
            const reader = new FileReader()
            reader.onload = async (event) => {
                const data = event.target.result
                const workbook = window.XLSX.read(data, { type: 'binary' })

                const _providerMap = {}
                this.providerList.forEach((item) => {
                    _providerMap[item.name] = item
                })

                const _contactTypeMap = {}
                contactTypeList.forEach((item) => {
                    _contactTypeMap[item.name] = item.val
                })

                if (workbook.SheetNames[0]) {
                    let _tableData = window.XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
                    // 是否超过1000条
                    this.alertContainer = {
                        state: _tableData.length > 1000,
                        content: `共上传 ${_tableData.length}条表单， 只显示前1000条数据`,
                    }

                    _tableData = _tableData.slice(0, 1000)
                    _tableData.forEach((item) => {
                        item.error = ''
                        item.uploadState = 0
                        item.params = {
                            providerNo: '',
                            contactType: '',
                            commitTime: '',
                            contact: item['联系方式'] || '',
                            consultContent: item['咨询内容'] || '',
                            ip: item['IP'] || '',
                            province: item['省'] || '',
                            city: item['市'] || '',
                            orderNo: item['form.订单号'] || '',
                            name: item['form.收货姓名'] || '',
                            address: item['form.收货地址'] || '',
                        }

                        if (_providerMap[item['渠道名称']]) {
                            const _projectNos = _providerMap[item['渠道名称']]['projectNos'] || []
                            if (_projectNos.length === 0 || _projectNos.includes(this.activeProjectNo)) {
                                item.params.providerNo = _providerMap[item['渠道名称']]['providerNo']
                            } else {
                                item.error = '【渠道不在当前项目内】'
                            }
                        } else {
                            item.error = '【渠道】异常'
                        }

                        if (!item['联系方式']) {
                            item.error = '【联系方式】未输入'
                        }

                        if (_contactTypeMap[item['联系类型']]) {
                            item.params.contactType = _contactTypeMap[item['联系类型']]
                        } else {
                            item.error = '【联系类型】异常'
                        }

                        if (item['表单时间'] && item['表单时间'] > 0) {
                            const _n = parseInt(item['表单时间']) || 0
                            if (_n === 0) {
                                item.error = '【表单时间】输入异常'
                            } else {
                                const _date = new Date(Math.round((item['表单时间'] - 25569) * 86400 * 1000))
                                const _formattedDate = _date.toISOString().replace('T', ' ').slice(0, 19)
                                item['表单时间'] = _formattedDate
                                if (!dayjs().isAfter(dayjs(_formattedDate))) {
                                    item.error = '【表单时间】超过当前时间'
                                } else {
                                    item.params.commitTime = _formattedDate
                                }
                            }
                        } else {
                            item.error = '【表单时间】未输入'
                        }

                        if (item['归属']) {
                            if (this.staffMap[item['归属']]) {
                                item.params.assignStaffId = this.staffMap[item['归属']]
                            } else {
                                item.error = '【归属】输入异常'
                            }
                        }
                    })
                    this.tableData = _tableData
                } else {
                    this.$message.warning('未查询到数据')
                }
            }
            reader.readAsBinaryString(file)
            this.uuid = uuidv4()
            return false
        },
        onSelectionChange(vals) {
            this.checkedItem = vals
        },
        onItemSelectState(row) {
            return !row.error
        },
        async onSubmit() {
            if (this.checkedItem.length === 0) {
                this.$message.warning('请选择提交数据')
            } else {
                this.uploadProgress = { state: true, progress: 0, success: 0, error: 0 }
                for (let index = 0; index < this.checkedItem.length; index++) {
                    const _item = this.checkedItem[index]
                    const _params = this.checkedItem[index]['params']

                    if (_item['form.商品'] || _item['form.金额'] || _item['form.带货达人']) {
                        const _l = []
                        if (_item['form.商品']) _l.push({ name: '商品名称', value: _item['form.商品'] })
                        if (_item['form.金额']) _l.push({ name: '金额', value: _item['form.金额'] })
                        if (_item['form.带货达人']) _l.push({ name: '带货达人', value: _item['form.带货达人'] })
                        _params.formDetail = JSON.stringify(_l)
                    }
                    _params.projectNo = this.activeProjectNo
                    _params.optId = this.userInfo.id
                    const res = await batchUploadxbTrackForm({ ..._params }).catch((e) => e.data)
                    if (res.code === 200) {
                        this.checkedItem[index].uploadState = 1
                        this.uploadProgress.success += 1
                    } else {
                        this.checkedItem[index].error = res.message
                        this.checkedItem[index].uploadState = -1
                        this.uploadProgress.error += 1
                    }
                    this.uploadProgress.progress = parseInt(((index + 1) / this.checkedItem.length) * 100)
                }
                this.$refs.tableContent.clearSelection()
                this.uploadProgress.state = false
            }
        },

        onReset() {
            this.fileName = ''
            this.tableData = []
            this.uuid = uuidv4()
            this.activeProjectNo = ''
            this.uploadProgress = { state: false, progress: 0, success: 0, error: 0 }
            this.alertContainer = { state: false, content: '' }
        },

        getStaffListApi() {
            getStaffList({}).then((res) => {
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.name] = item.id
                })
                this.staffMap = _map
            })
        },
    },
}
</script>
<style lang="less" scoped>
.opts {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .template {
        margin: 0 20px 4px 20px;
        font-size: 13px;
    }
}
.descriptions {
    margin: 20px 32px;
}
.alert {
    width: 300px;
    margin-left: 20px;
}
.upload_container {
    display: flex;
    align-items: center;
    .el-progress {
        width: 200px;
    }
    .progress {
        margin-left: 12px;
        display: flex;
        align-items: center;
        .el-link {
            width: 72px;
        }
    }
}
</style>
