<template>
    <el-dialog title="批量上传" :visible.sync="isShow" width="480px" :before-close="onClose" @open="onOpend">
        <el-form v-if="formData" :model="formData" :rules="rules" ref="formData" label-width="100px">
            <el-form-item label="项目" prop="projectNo">
                <ProjectSelect :defaultValue="formData.projectNo" @change="(val) => (formData.projectNo = val)" />
            </el-form-item>
            <el-form-item label="表单文件" prop="contact">
                <el-upload
                    :on-error="onUploadError"
                    :on-success="onUploadSuccess"
                    :action="`${uploadPath}&projectNo=${formData.projectNo}`"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :show-file-list="false"
                >
                    <el-button plain type="primary">立即导入</el-button>
                </el-upload>
                <el-link class="float_left_btn template" type="primary" :href="formExcelTemplate">
                    下载导入表单模板
                    <el-tooltip effect="dark" content="红色必填，黄色 直播带货必填 ，绿色选填" placement="top">
                        <i class="el-icon-warning-outline" />
                    </el-tooltip>
                </el-link>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import { mapGetters } from 'vuex'
import _config from '@/js/config'

export default {
    props: ['isShow', 'providerList', 'projectNo'],
    components: { ProjectSelect },
    data() {
        return {
            formExcelTemplate: _config.formExcelTemplate,
            uploadPath: '',

            formData: null,
            rules: {
                projectNo: [{ required: true, message: '请选择', trigger: 'change' }],
            },
        }
    },
    computed: { ...mapGetters(['userInfo']) },
    methods: {
        onOpend() {
            this.formData = { projectNo: this.projectNo }
            this.uploadPath = `${_config.HOST}/hunter-cms/xbTrackFormRecord/upload?token=${this.userInfo.token}`
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },

        onUploadSuccess(res) {
            if (res.code === 200) {
                this.$message.info(res.data)
            } else {
                this.$message.error(res.message)
            }
            this.$emit('onClose', true)
        },
        onUploadError() {
            this.$message.error('上传失败')
        },
    },
}
</script>
<style lang="less" scoped>
.el-select {
    width: 100%;
}
</style>
