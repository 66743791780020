<template>
    <el-drawer title="批量导入" :visible.sync="isShow" @opened="onOpened" :before-close="onClose" size="1100px">
        <div class="opts">
            <el-upload
                :on-error="onUploadError"
                :on-success="onUploadSuccess"
                :action="uploadPath"
                :before-upload="onUploadBefore"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :show-file-list="false"
            >
                <el-button>批量导入</el-button>
            </el-upload>
            <span class="file_name" v-if="fileName">{{ fileName }}</span>
            <el-link class="template" type="primary" :href="dyFormExcelTemplate">
                下载导入表单模板
                <el-tooltip effect="dark" content="红色必填，绿色选填" placement="top">
                    <i class="el-icon-warning-outline" />
                </el-tooltip>
            </el-link>
        </div>
        <el-select
            class="status_query"
            v-model="statusVal"
            filterable
            clearable
            @change="onStatusChange"
            placeholder="上传状态"
        >
            <el-option
                v-for="item in statusList"
                :key="item.val"
                :label="`${item.name} [${item.num}]`"
                :value="item.val"
            />
        </el-select>

        <el-table
            ref="table"
            height="calc(100% - 120px)"
            :data="tableData"
            border
            stripe
            @selection-change="onSelectionChange"
        >
            <!-- <el-table-column type="expand">
                <template slot-scope="props">
                    <el-descriptions class="descriptions" :column="2" border>
                        <el-descriptions-item label="收货姓名">{{ props.row.receiverName }}</el-descriptions-item>
                        <el-descriptions-item label="收货电话">{{ props.row.receiverTelNo }}</el-descriptions-item>
                        <el-descriptions-item label="收货地址">{{ props.row.receiverAddress }}</el-descriptions-item>
                    </el-descriptions>
                </template>
            </el-table-column> -->
            <el-table-column align="center" type="selection" width="55" :selectable="onItemSelectState" />
            <el-table-column prop="orderNo" label="项目" align="center">
                <template slot-scope="scope">
                    <span v-if="projectMap">{{ projectMap[scope.row.projectNo] }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="orderNo" label="渠道名称" align="center">
                <template slot-scope="scope">
                    <span v-if="projectMap">{{ providerMap[scope.row.providerNo] }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" align="center" />
            <el-table-column prop="orderNo" label="订单号" align="center" />
            <el-table-column prop="contact" label="联系方式" align="center" />
            <el-table-column prop="commitTime" label="表单时间" width="96" align="center" />
            <el-table-column label="归属" width="96" align="center">
                <template slot-scope="scope">
                    <StaffName
                        v-if="staffMap && staffMap[scope.row.assignStaffId]"
                        :staff="staffMap[scope.row.assignStaffId]"
                    />
                    <span v-else></span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="状态" width="100">
                <template slot-scope="scope">
                    <el-tag effect="dark" :type="statusMap[scope.row.status]['tag']">
                        {{ statusMap[scope.row.status]['name'] }}
                    </el-tag>
                    <div>{{ scope.row.statusDesc }}</div>
                </template>
            </el-table-column>
        </el-table>
        <br />
        <div class="upload_container">
            <el-button
                type="primary"
                plain
                :disabled="uploadProgress.state || checkedItem.length === 0"
                @click="onSubmit"
            >
                {{ uploadProgress.state ? '上传中' : `批量提交 ${checkedItem.length}` }}
            </el-button>
            <div class="progress" v-if="uploadProgress.progress !== 0">
                <el-link type="success">成功: {{ uploadProgress.success }}</el-link>
                <el-link type="danger">失败: {{ uploadProgress.error }}</el-link>
                <el-progress :percentage="uploadProgress.progress" />
            </div>
        </div>
    </el-drawer>
</template>
<script>
import { batchUploadDyForm } from '@/js/api/advertApi.js'
import { mapGetters } from 'vuex'
import { getStaffList } from '@/js/api/staffApi.js'
import _config from '@/js/config'

let _tableData = []
export default {
    props: ['isShow', 'providerList'],
    computed: {
        ...mapGetters(['userInfo', 'projectList']),
        uploadPath: function () {
            return `${_config.HOST}/hunter-cms/xbTrackFormRecord/doudian/upload/check?token=${this.userInfo.token}`
        },
        projectMap: function () {
            const _map = {}
            this.projectList.forEach((i) => (_map[i.projectNo] = i.projectName))
            return _map
        },
        providerMap: function () {
            const _map = {}
            this.providerList.forEach((i) => (_map[i.providerNo] = i.name))
            return _map
        },
    },
    data() {
        return {
            dyFormExcelTemplate: _config.dyFormExcelTemplate,
            fileName: '',

            statusList: [
                { name: '待上传', val: 'SUCCESS', num: 0 },
                { name: '警告', val: 'WARN', num: 0 },
                { name: '未通过校验', val: 'FAIL', num: 0 },
                { name: '上传成功', val: 'UPLOAD_SUCCESS', num: 0 },
                { name: '上传失败', val: 'UPLOAD_FAILED', num: 0 },
            ],
            statusMap: {
                SUCCESS: { name: '待上传', tag: 'info' },
                WARN: { name: '警告', tag: 'warning' },
                FAIL: { name: '未通过校验', tag: 'danger' },
                UPLOAD_SUCCESS: { name: '上传成功', tag: 'success' },
                UPLOAD_FAILED: { name: '上传失败', tag: 'danger' },
            },
            statusVal: '',
            tableData: [],
            staffMap: null,

            checkedItem: [],
            uploadProgress: { state: false, progress: 0, success: 0, error: 0 },
        }
    },
    methods: {
        onOpened() {
            this.updateState = false
            this.uploadProgress = { state: false, progress: 0, success: 0, error: 0 }
            this.checkedItem = []
        },
        onClose() {
            this.statusVal = ''
            this.fileName = ''
            this.checkedItem = []
            this.tableData = []
            this.$emit('onClose', this.updateState)
        },

        onUploadBefore(file) {
            this.fileName = file.name
            this._loading = this.$loading({ text: 'Loading', background: 'rgba(0, 0, 0, 0.6)' })
        },
        onUploadSuccess(res) {
            if (this._loading) this._loading.close()
            this.statusVal = ''
            _tableData = []
            this.tableData = []
            this.uploadProgress = { state: false, progress: 0, success: 0, error: 0 }
            if (res.code === 200) {
                _tableData = res.data
                this.tableData = _tableData
                this.updateNum()
                const _staffIds = []
                _tableData.forEach((i) => {
                    if (i.assignStaffId && !_staffIds.includes(i.assignStaffId)) {
                        _staffIds.push(i.assignStaffId)
                    }
                })
                getStaffList({ staffIds: _staffIds }).then((staff) => {
                    const _map = {}
                    staff.data.forEach((i) => {
                        _map[i.id] = i
                    })
                    this.staffMap = _map
                })
            } else {
                this.$message.error(res.message)
            }
        },
        onUploadError() {
            this.statusVal = ''
            _tableData = []
            this.tableData = []
            this.$message.error('上传失败')
            if (this._loading) this._loading.close()
        },

        onSelectionChange(vals) {
            this.checkedItem = vals
        },
        onItemSelectState(row) {
            return ['SUCCESS', 'WARN'].includes(row.status)
        },

        onStatusChange() {
            if (this.statusVal) {
                this.tableData = _tableData.filter((i) => i.status === this.statusVal)
            } else {
                this.tableData = _tableData
            }
        },

        async onSubmit() {
            const _loading = this.$loading({ text: 'Loading', background: 'rgba(0, 0, 0, 0.6)' })
            this.uploadProgress = { state: true, progress: 0, success: 0, error: 0 }
            for (let i = 0; i < this.checkedItem.length; i++) {
                const _item = this.checkedItem[i]
                const res = await batchUploadDyForm({
                    ..._item,
                    status: undefined,
                    statusDesc: undefined,
                    // contact: _item.contact,
                    // orderNo: _item.orderNo,
                    // receiverName: _item.receiverName,
                    // receiverAddress: _item.receiverAddress,
                    // receiverTelNo: _item.receiverTelNo,
                    // providerNo: _item.providerNo,
                    // projectNo: _item.projectNo,
                }).catch((e) => e.data)

                if (res.code === 200) {
                    this.checkedItem[i].status = 'UPLOAD_SUCCESS'
                    this.uploadProgress.success += 1
                } else {
                    this.checkedItem[i].status = 'UPLOAD_FAILED'
                    this.checkedItem[i].statusDesc = res.message
                    this.uploadProgress.error += 1
                }
                this.uploadProgress.progress = parseInt(((i + 1) / this.checkedItem.length) * 100)
            }
            _loading.close()
            this.uploadProgress.state = false
            this.$refs.table.clearSelection()
            this.updateNum()
            this.updateState = true
        },
        updateNum() {
            const _map = {}
            _tableData.forEach((i) => {
                if (!_map[i.status]) _map[i.status] = 0
                _map[i.status] += 1
            })
            this.statusList.forEach((i) => {
                i.num = _map[i.val] || 0
            })
        },
    },
}
</script>
<style lang="less" scoped>
.opts {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .template {
        margin-left: 20px;
        font-size: 13px;
    }
    .file_name {
        margin-left: 8px;
        font-size: 12px;
        color: #666666;
    }
}
.status_query {
    position: absolute;
    right: 20px;
    top: 72px;
}
.upload_container {
    display: flex;
    align-items: center;
    .el-progress {
        width: 200px;
    }
    .progress {
        margin-left: 12px;
        display: flex;
        align-items: center;
        .el-link {
            width: 72px;
        }
    }
}
</style>
